'use strict';

angular.module('kljDigitalLibraryApp')
    .service('searchHistoryService', ["$q", "common", "appConfig",
        function($q, common, appConfig) {

            var baseUrl = '/api/users';

            this.getSearchHistoryInfo = function(payload) {

                var deferred = $q.defer();

                common.callApi('POST',
                    baseUrl + '/get/searchHistory',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    payload)

                .then(function(response) {

                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                });

                return deferred.promise;
            }


        }
    ]);